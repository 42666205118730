<template>
<router-view></router-view>
</template>

<script>

import "./default.scss";
export default {
  name: 'App',
  components: {
  }
}
</script>
