import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
import { createPinia } from 'pinia';
import { Button,showLoadingToast } from 'vant';
// import clipboard from 'clipboard';
import VConsole from "vconsole";
import 'vant/lib/index.css';



// window.vConsole = new VConsole();
var app = createApp(App);
// app.prototype.clipboard = clipboard;
app.use(createPinia()).use(router).use(Button).mount('#app');

router.beforeEach(async (to, from, next) => {
    const title = (to.meta.title ? to.meta.title : '') + '-工作平台'
    document.title = title;
    // ViewUIPlus.LoadingBar.start();

    // if (to.path != from.path) {
    //     showLoadingToast({
    //         message: '加载中...',
    //         forbidClick: true,
    //     });
    // }

    next();
})


router.afterEach(() => {
    
});