
import { createRouter, createWebHashHistory } from 'vue-router'
const routes = [
    // {
    //     path: '/login',
    //     meta: {
    //         title: '登录'
    //     },
    //     component: () => import('./views/login.vue')
    // },
    {
        path: '/wxok',
        meta: {
            title: '微信登录授权'
        },
        component: () => import('./views/wxok.vue')
    },
    {
        path: '/loginresult',
        meta: {
            title: '授权结果'
        },
        component: () => import('./views/loginresult.vue')
    },
    {
        path: '/noauth',
        meta: {
            title: '无权限'
        },
        component: () => import('./views/noauth.vue')
    },
    // {
    //     path: '/noauth',
    //     meta: {
    //         title: '无权限'
    //     },
    //     component: () => import('./views/noauth.vue')
    // },
    {
        path: '/',
        meta: {
            title: '首页'
        },
        component: () => import('./views/index.vue'),
        children: [{
            path: '/',
            meta: {
                title: '首页',
                bread: 1
            },
            component: () => import('./views/default.vue')
        }, {
            path: '/authing',
            meta: {
                title: '权限验证'
            },
            component: () => import('./views/authing.vue')
        }, {
            path: '/noauth',
            meta: {
                title: '无权限'
            },
            component: () => import('./views/noauth.vue')
        }, {
            path: '/pageerror',
            meta: {
                title: '系统错误'
            },
            component: () => import('./views/pageerror.vue')
        }, {
            path: '/consultlist',
            meta: {
                title: '客资列表'
            },
            component: () => import('./views/consult/consultlist.vue')
        }, {
            path: '/addconsult',
            meta: {
                title: '推送客资'
            },
            component: () => import('./views/consult/addconsult.vue')
        }, {
            path: '/consultdetail',
            meta: {
                title: '客资详情'
            },
            component: () => import('./views/consult/consultdetail.vue')
        }, {
            path: '/addspecialconsult',
            meta: {
                title: '特殊客资'
            },
            component: () => import('./views/consult/addspecialconsult.vue')
        }, {
            path: '/addprivateconsult',
            meta: {
                title: '私发客资'
            },
            component: () => import('./views/consult/addprivateconsult.vue')
        }, {
            path: '/addorder',
            meta: {
                title: '编辑客资'
            },
            component: () => import('./views/crm/addorder.vue')
        }, {
            path: '/adddraworder',
            meta: {
                title: '编辑客资'
            },
            component: () => import('./views/crm/adddraworder.vue')
        }, {
            path: '/orderlist',
            meta: {
                title: '销售订单'
            },
            component: () => import('./views/crm/orderlist.vue')
        }, {
            path: '/orderdetail',
            meta: {
                title: '订单详情'
            },
            component: () => import('./views/crm/orderdetail.vue')

        }, {
            path: '/drawlist',
            meta: {
                title: '成品图纸列表'
            },
            component: () => import('./views/product/drawlist.vue')
        }, {
            path: '/peripherallist',
            meta: {
                title: '周边产品列表'
            },
            component: () => import('./views/product/peripherallist.vue')
        },
        {
            path: '/addperipheralorder',
            meta: {
                title: '编辑客资'
            },
            component: () => import('./views/crm/addperipheralorder.vue')
        }, {
            path: '/addprojectorder',
            meta: {
                title: '编辑客资'
            },
            component: () => import('./views/crm/addprojectorder.vue')
        }, {
            path: '/scheduling',
            meta: {
                title: '排班'
            },
            component: () => import('./views/personnel/scheduling.vue')
        }, {
            path: '/personnelapply',
            meta: {
                title: '请假'
            },
            component: () => import('./views/personnel/personnelapply.vue')
        }, {
            path: '/personnelapplydatelist',
            meta: {
                title: '请假日期'
            },
            component: () => import('./views/personnel/personnelapplydatelist.vue')
        }, {
            path: '/schedulingswitch',
            meta: {
                title: '调休'
            },
            component: () => import('./views/personnel/schedulingswitch.vue')
        }, {
            path: '/consult',
            meta: {
                title: '客资管理'
            },
            component: () => import('./views/consult/consult.vue')
        }, {
            path: '/mytask',
            meta: {
                title: '跟进客户'
            },
            component: () => import('./views/crm/mytask.vue')
        }, {
            path: '/myaftertask',
            meta: {
                title: '售后客户'
            },
            component: () => import('./views/crm/myaftertask.vue')

        }, {
            path: '/customerdetail',
            meta: {
                title: '客户详情'
            },
            component: () => import('./views/crm/customerdetail.vue')
        }, {
            path: '/applylist',
            meta: {
                title: '申请列表'
            },
            component: () => import('./views/personnel/applylist.vue')
        }, {
            path: '/applydetail',
            meta: {
                title: '申请详情'
            },
            component: () => import('./views/personnel/applydetail.vue')
        }, {
            path: '/checkorder',
            meta: {
                title: '核对订单'
            },
            component: () => import('./views/crm/checkorder.vue')
        }, {
            path: '/checkorderdetail',
            meta: {
                title: '核对订单详情'
            },
            component: () => import('./views/crm/checkorderdetail.vue')
        }, {
            path: '/seacustomer',
            meta: {
                title: '公海客户'
            },
            component: () => import('./views/crm/seacustomer.vue')
        }, {
            path: '/completecustomerinfo',
            meta: {
                title: '客户详情'
            },
            component: () => import('./views/crm/completecustomerinfo.vue')
        }, {
            path: '/projectlist',
            meta: {
                title: '我的项目'
            },
            component: () => import('./views/crm/projectlist.vue')
        }, {
            path: '/projectlistdetail',
            meta: {
                title: '项目详情'
            },
            component: () => import('./views/crm/projectlistdetail.vue')
        }, {
            path: '/teamprojectlist',
            meta: {
                title: '团队项目'
            },
            component: () => import('./views/crm/teamprojectlist.vue')
        }, {
            path: '/useractive',
            meta: {
                title: '账户激活'
            },
            component: () => import('./views/register/useractive.vue')
        },
        {
            path: '/userlist',
            meta: {
                title: '用户列表'
            },
            component: () => import('./views/crm/userlist.vue')
        },
        {
            path: '/projecttemplate',
            meta: {
                title: '设计套餐'
            },
            component: () => import('./views/crm/projectextra/projecttemplate.vue')
        },
        {
            path: '/projectremitdetail',
            meta: {
                title: '汇款明细'
            },
            component: () => import('./views/crm/projectextra/remitdetail.vue')
        },
        {
            path: '/projectdetail',
            meta: {
                title: '项目详情'
            },
            component: () => import('./views/crm/projectdetail.vue')
        },
        {
            path: '/refundlist',
            meta: {
                title: '退款管理'
            },
            component: () => import('./views/crm/refundlist.vue')
        },
        {
            path: '/refunddetail',
            meta: {
                title: '退款详情'
            },
            component: () => import('./views/crm/refunddetail.vue')
        },
        {
            path: '/remitdetail',
            meta: {
                title: '汇款详情'
            },
            component: () => import('./views/crm/remitdetail.vue')
        },
        {
            path: '/remitlist',
            meta: {
                title: '汇款列表'
            },
            component: () => import('./views/crm/remitlist.vue')
        },
        {
            path: '/spreadlist',
            meta: {
                title: '推广列表'
            },
            component: () => import('./views/crm/spreadlist.vue')
        },
        {
            path: '/spreadrecordlist',
            meta: {
                title: '客户浏览列表'
            },
            component: () => import('./views/crm/spreadrecordlist.vue')
        },
        {
            path: '/spreadrecorddetail',
            meta: {
                title: '客户浏览详情'
            },
            component: () => import('./views/crm/spreadrecorddetail.vue')
        },
        {
            path: '/spreaddetail',
            meta: {
                title: '推广详情'
            },
            component: () => import('./views/crm/spreaddetail.vue')
        },
        {
            path: '/myinfo',
            meta: {
                title: '个人详情'
            },
            component: () => import('./views/users/myinfo.vue')
        },
        {
            path: '/remitcurrentphase',
            meta: {
                title: '阶段汇款'
            },
            component: () => import('./views/crm/remitcurrentphase.vue')
        },
        {
            path: '/applyonlinenoscheduling',
            meta: {
                title: '加班申请'
            },
            component: () => import('./views/auth/workovertime.vue')
        },
        {
            path: '/personneldetail',
            meta: {
                title: '请假申请详情'
            },
            component: () => import('./views/auth/personnelapply.vue')
        },
        {
            path: '/schedulingdetail',
            meta: {
                title: '排版申请详情'
            },
            component: () => import('./views/auth/schedulingdetail.vue') 
        },
        {
            path: '/useraddapply',
            meta: {
                title: '账号创建申请'
            },
            component: () => import('./views/auth/useraddapply.vue') 
        },
        
        // {
        //     path: '/uploadtradeinproject',
        //     meta: {
        //         title: '上传付款截图'
        //     },
        //     component: () => import('./views/crm/uploadtradeinproject.vue')
        // }
            //#region 
            // {
            //     path: '/contract',
            //     meta: {
            //         title: '我的订单',
            //         bread:1
            //     },
            //     component: () => import('./views/contract/index.vue')
            // },{
            //     path: '/contractview',
            //     meta: {
            //         title: '在线合同',
            //         bread:2
            //     },
            //     component: () => import('./views/contract/view.vue')
            // },{
            //     path: '/contractsignature',
            //     meta: {
            //         title: '合同签订',
            //         bread:2
            //     },
            //     component: () => import('./views/contract/signature.vue')
            // },{
            //     path: '/duty',
            //     meta: {
            //         title: '授权结果'
            //     },
            //     component: () => import('./views/duty/index.vue')
            // },{
            //     path: '/consultlist',
            //     meta: {
            //         title: '客资编辑方式'
            //     },
            //     component: () => import('./views/consult/consultlist.vue')
            // },{
            //     path: '/addconsult',
            //     meta: {
            //         title: '推送客资'
            //     },
            //     component: () => import('./views/consult/addconsult.vue')
            // },{
            //     path: '/consultdetail',
            //     meta: {
            //         title: '客资详情'
            //     },
            //     component: () => import('./views/consult/consultdetail.vue')
            // },{
            //     path: '/addspecialconsult',
            //     meta: {
            //         title: '特殊客资'
            //     },
            //     component: () => import('./views/consult/addspecialconsult.vue')
            // },{
            //     path: '/addprivateconsult',
            //     meta: {
            //         title: '私发客资'
            //     },
            //     component: () => import('./views/consult/addprivateconsult.vue')
            // },{
            //     path: '/addorder',
            //     meta: {
            //         title: '编辑客资'
            //     },
            //     component: () => import('./views/crm/addorder.vue')
            // },{
            //     path: '/adddraworder',
            //     meta: {
            //         title: '编辑客资'
            //     },
            //     component: () => import('./views/crm/adddraworder.vue')
            // },{
            //     path: '/addperipheralorder',
            //     meta: {
            //         title: '编辑客资'
            //     },
            //     component: () => import('./views/crm/addperipheralorder.vue')
            // },{
            //     path: '/addprojectorder',
            //     meta: {
            //         title: '编辑客资'
            //     },
            //     component: () => import('./views/crm/addprojectorder.vue')
            // },{
            //     path: '/scheduling',
            //     meta: {
            //         title: '排班' 
            //     },
            //     component: () => import('./views/personnel/scheduling.vue')
            // },{
            //     path: '/personnelapply',
            //     meta: {
            //         title: '请假'
            //     },
            //     component: () => import('./views/personnel/personnelapply.vue')
            // },{
            //     path: '/personnelapplydatelist',
            //     meta: {
            //         title: '请假日期'
            //     },
            //     component: () => import('./views/personnel/personnelapplydatelist.vue')
            // },{
            //     path: '/schedulingswitch',
            //     meta: {
            //         title: '调休'
            //     },
            //     component: () => import('./views/personnel/schedulingswitch.vue')
            // },{
            //     path: '/checkorder',
            //     meta: {
            //         title: '核对订单'
            //     },
            //     component: () => import('./views/crm/checkorder.vue')
            // },{
            //     path: '/checkorderdetail',
            //     meta: {
            //         title: '核对订单详情'
            //     },
            //     component: () => import('./views/crm/checkorderdetail.vue')
            // },{
            //     path: '/orderlist',
            //     meta: {
            //         title: '销售订单'
            //     },
            //     component: () => import('./views/crm/orderlist.vue')
            // },{
            //     path: '/orderdetail',
            //     meta: {
            //         title: '订单详情'
            //     },
            //     component: () => import('./views/crm/orderdetail.vue')
            // },{
            //     path: '/drawlist',
            //     meta: {
            //         title: '成品图纸列表'
            //     },
            //     component: () => import('./views/product/drawlist.vue')
            // },{
            //     path: '/peripherallist',
            //     meta: {
            //         title: '周边产品列表'
            //     },
            //     component: () => import('./views/product/peripherallist.vue')
            // },{
            //     path: '/uploadtrade',
            //     meta: {
            //         title: '上传付款截图'
            //     },
            //     component: () => import('./views/crm/uploadtrade.vue')

            //     path: '/uploadtradeinproject',
            //     meta: {
            //         title: '上传付款截图'
            //     },
            //     component: () => import('./views/crm/uploadtradeinproject.vue')

            //     path: '/userlist',
            //     meta: {
            //         title: '用户列表'
            //     },
            //     component: () => import('./views/crm/userlist.vue')
            // },{
            //     path: '/seacustomer',
            //     meta: {
            //         title: '公海客户'
            //     },
            //     component: () => import('./views/crm/seacustomer.vue')
            // },{
            //     path: '/completecustomerinfo',
            //     meta: {
            //         title: '客户详情'
            //     },
            //     component: () => import('./views/crm/completecustomerinfo.vue')
            // },{
            //     path: '/privatecustomer',
            //     meta: {
            //         title: '私发客户'
            //     },
            //     component: () => import('./views/crm/privatecustomer.vue')
            // },{
            //     path: '/mytask',
            //     meta: {
            //         title: '跟进客户'
            //     },
            //     component: () => import('./views/crm/mytask.vue')
            // },{
            //     path: '/myaftertask',
            //     meta: {
            //         title: '售后客户'
            //     },
            //     component: () => import('./views/crm/myaftertask.vue')
            // },{
            //     path: '/consult',
            //     meta: {
            //         title: '客资管理'
            //     },
            //     component: () => import('./views/consult/consult.vue')
            // },{
            //     path: '/customerdetail',
            //     meta: {
            //         title: '客户详情'
            //     },
            //     component: () => import('./views/crm/customerdetail.vue')
            // },{
            //     path: '/applylist',
            //     meta: {
            //         title: '申请列表'
            //     },
            //     component: () => import('./views/personnel/applylist.vue')
            // },{
            //     path: '/applydetail',
            //     meta: {
            //         title: '申请详情'
            //     },
            //     component: () => import('./views/personnel/applydetail.vue')
            // },{
            //     path: '/projectlist',
            //     meta: {
            //         title: '项目管理'
            //     },
            //     component: () => import('./views/crm/projectlist.vue')
            // },{
            //     path: '/projectlistdetail',
            //     meta: {
            //         title: '项目详情'
            //     },
            //     component: () => import('./views/crm/projectlistdetail.vue')
            // }
            //#endregion
        ]
    }

];
const router = createRouter({
    history: createWebHashHistory(process.env.BASE_URL),
    routes: routes
})
export default router;